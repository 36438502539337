import './postcodeAnywhereAddress.css'
import externalScript from '@iris/externalScript'
import { idMixin as safeId } from 'bootstrap-vue/esm/mixins/id'
import Vue, { ComponentOptions } from 'vue'
import LoqateApi from '@iris/loqateApi'

interface AddressAutoComplete extends Vue {
  _control: Loqate.Address;
  loqateResponse: any;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  safeId (prefix: string): string;
  $loqate: LoqateApi
}

/**
 * Pulled out of family details form and common props with alt
 * payer information as well. Also css is loaded in once and so is the js via external async loader
 *
 */
export default {
  mixins: [safeId],
  inject: ['$loqate'],
  async mounted (this: AddressAutoComplete): Promise<void> {
    const [pca] = await Promise.all([externalScript<Loqate.Base>('//services.postcodeanywhere.co.uk/js/address-3.70.js', 'pca'), this.$nextTick()])
    this._control = new pca.Address([ // fields
      { element: this.safeId('pc'), field: 'PostalCode', mode: pca.fieldMode.SEARCH },
      { element: this.safeId('a1'), field: 'FormattedLine1', mode: pca.fieldMode.SEARCH },
      { element: this.safeId('a2'), field: 'FormattedLine2', mode: pca.fieldMode.SEARCH },
      { element: this.safeId('c'), field: 'City', mode: pca.fieldMode.SEARCH }
    ], {
      key: this.$loqate._defaults.Key,
      search: { countries: 'GB' }
    })
    this._control.listen('error', (message: string) => {
      // eslint-disable-next-line no-console
      console[message === 'No response' ? 'log' : 'error'](`PCA error: ${message}`)
    })
    this._control.listen('populate', (address, _variations) => {
      this.loqateResponse = address
      this.address1 = address.FormattedLine1
      this.address2 = address.FormattedLine2
      this.postcode = address.PostalCode
      this.city = address.City
      // make sure the validation is updated here otherwise the fields won't go green
      this.$v.address1!.$touch()
      // this.$v.address2.$touch()
      this.$v.postcode!.$touch()
      this.$v.city!.$touch()
    })
  },
  beforeDestroy (this: AddressAutoComplete): void {
    if (this._control) {
      this._control.destroy()
    }
  },
  methods: {
    postcodeFocus () {
      if (!this.address1 && !this.address2 && !this.city && this.postcode) {
        this._control.search(this.postcode)
      }
    }
  }
} as ComponentOptions<AddressAutoComplete>
